import React from 'react';
import PropTypes from 'prop-types';
import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {
  AppstoreAddOutlined,
  HomeOutlined,
  LoginOutlined,
  MenuOutlined,
  PhoneOutlined,
  TeamOutlined,
} from '@ant-design/icons';

const LeftMenu = ({mode}) => {
  return (
    <nav className={'md:w-[100vh] flex justify-end'}>
      <Menu mode={mode} className={'bg-transparent'}>
        <Menu.Item key={'home'}>
          <Link to={'/'}>
            <HomeOutlined style={{fontSize:20}} />
            <text className={'text-xl ml-2 font-semibold'}>Home</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'products'}>
          <Link to={'/products'}>
            <AppstoreAddOutlined style={{fontSize:20}} />
            <text className={'text-xl ml-2 font-semibold'}>Products</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'blog'}>
          <Link to={'/blog'}>
            <MenuOutlined style={{fontSize:20}} />
            <text className={'text-xl ml-2 font-semibold'}>Blog</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'aboutus'}>
          <Link to={'/aboutus'}>
            <TeamOutlined style={{fontSize:20}} />
            <text className={'text-xl ml-2 font-semibold'}>About</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'contactus'}>
          <Link to={'/contactus'}>
            <PhoneOutlined style={{fontSize:20}} />
            <text className={'text-xl ml-2 font-semibold'}>Contact</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'SignUp'}>
          <Link to={'/SignUp'}>
            <LoginOutlined style={{fontSize:20}} />
            <text className={'text-xl ml-2 font-semibold'}>LogIn</text>
          </Link>
        </Menu.Item>
      </Menu>
    </nav>
  );
};

LeftMenu.propTypes = {
  mode: PropTypes.oneOf(['horizontal', 'vertical', 'inline']).isRequired,
};

export default LeftMenu;

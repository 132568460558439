import {Image} from 'antd';
import Slider from 'react-slick';

import facebook from '../../../assets/images/Facebook.png';
import insta from '../../../assets/images/Instagram.png';
import youtube from '../../../assets/images/Youtube.png';
import telegram from '../../../assets/images/Telegram.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const data = [
  {
    id:1,
    img:facebook,

  },
  {
    id:2,
    img:insta,
  },
  {
    id:3,
    img:youtube,
  },
  {
    id:4,
    img:telegram,
  },
];

const settings = {
  dots: false,
  arrows:false,
  infinite: true,
  speed: 3000,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 1000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const AboutSlider = () => {
  return (
    <div>
      <div className={'flex justify-center items-center bg-infotech-100'}>
        <div className={'w-80 lg:w-[170vh]'}>
          <Slider {...settings}>
            {data.map((id, img) => (
              <div key={id}>
                <div className={'lg:w-72 lg:h-32'}>
                  <Image
                    src={id.img}
                    preview={false}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AboutSlider;

import {Button, Checkbox, Divider, Form, Input, Typography} from 'antd';
import {GoogleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const {Title, Text} = Typography;

const SignIn = () => {
  return (
    <section style={{position: 'relative'}}>
      <div style={{maxWidth: '1440px', margin: 'auto', padding: '0 24px'}}>
        <div style={{paddingTop: '128px', paddingBottom: '48px'}}>

          {/* Page header */}
          <div style={{maxWidth: '768px', margin: 'auto', textAlign: 'center', paddingBottom: '48px'}}>
            <Title>Welcome back. We exist to make entrepreneurship easier.</Title>
          </div>

          {/* Form */}
          <div style={{maxWidth: '384px', margin: 'auto'}}>
            <Form>
              <Form.Item>
                <Button
                  type={'primary'}
                  icon={<GoogleOutlined />}
                  style={{width: '100%', backgroundColor: '#d32f2f', borderColor: '#d32f2f'}}
                  size={'large'}
                >
                                    Sign in with Google
                </Button>
              </Form.Item>
            </Form>
            <Divider>Or, sign in with your email</Divider>
            <Form layout={'vertical'}>
              <Form.Item
                label={'Email'}
                name={'email'}
                rules={[{required: true, message: 'Please input your email!'}]}
              >
                <Input type={'email'} placeholder={'you@yourcompany.com'} />
              </Form.Item>
              <Form.Item
                label={'Password'}
                name={'password'}
                rules={[{required: true, message: 'Please input your password!'}]}
              >
                <Input.Password placeholder={'Password (at least 10 characters)'} />
              </Form.Item>
              <Form.Item>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Checkbox>Keep me signed in</Checkbox>
                  <Link href={'/reset-password'}>
                    <a style={{color: '#722ed1'}}>Forgot Password?</a>
                  </Link>
                </div>
              </Form.Item>
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'} style={{width: '100%', backgroundColor: '#722ed1', borderColor: '#722ed1'}} size={'large'}>
                                    Sign in
                </Button>
              </Form.Item>
            </Form>
            <Text style={{display: 'block', textAlign: 'center', marginTop: '24px', color: '#8c8c8c'}}>
                            Don’t you have an account? <Link href={'/signup'}><a style={{color: '#722ed1'}}>Sign up</a></Link>
            </Text>
          </div>

        </div>
      </div>
    </section>
  );
};

export default SignIn;

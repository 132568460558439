import 'antd/dist/reset.css';
import React from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import NavBar from './screenComponents/Navbar/NavBar';
import {Home} from './screenComponents/Home';
import {AboutUs} from './screenComponents/AboutUs';
import {Products} from './screenComponents/Products';
import {Blog} from './screenComponents/Blog';
import {BlogDetailScreen} from './screenComponents/Blog/BlogList/BlogDetailScreen';
import {Footer} from './screenComponents/Footer';
import {ContactUs} from './screenComponents/ContactUs';
import {SignIn} from './screenComponents/SignIn';
import {SignUp} from './screenComponents/SignUp';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/aboutus'} element={<AboutUs />} />
        <Route path={'/products'} element={<Products />} />
        <Route path={'/blog'} element={<Blog />} />
        <Route path={'/contactus'} element={<ContactUs />} />
        <Route path={'/signin'} element={<SignIn />} />
        <Route path={'/signup'} element={<SignUp />} />
        <Route path={'/article/:id'} element={<BlogDetailScreen />} />
        <Route path={'/catIndex/:id'} element={<Products />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;

import contact from '../../assets/images/bgcon.jpg';
import contactus from '../../assets/images/contactus.svg';
import React, {useEffect, useState} from 'react';
import {DoubleRightOutlined} from '@ant-design/icons';
import {Button, ConfigProvider, Image, Input} from 'antd';
import TextArea from 'antd/es/input/TextArea';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    message: '',
  });

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSend = () => {
    const whatsappMessage = `Name: ${formData.name}\nPhone No.: ${formData.phoneNumber}\nEmail: ${formData.email}\nMessage: ${formData.message}`;
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappLink = `https://wa.me/919011718888?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${contact})`,
          height: '50vh',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
        }}
        className={'bg-local lg:bg-fixed'}>
        <div>
          <div className={'text-2xl md:text-5xl md:flex md:h-96 p-8 md:items-center'}>
            <h1 className={'bg-quantum-500 text-white p-3 w-44 md:w-80'}>Contact Us</h1>
          </div>
        </div>
      </div>
      <div className={'md:p-6 p-4'}>
        <h1>Home <DoubleRightOutlined /> Contact Us</h1>
      </div>

      <div className={'p-10 lg:flex lg:flex-row justify-center'}>
        <div className={'lg:p-10 w-[40vh] lg:w-[80vh]'}>
          <div>
            <h1 className={'text-4xl'}>Contact Us</h1>
            <p>We are here to Help</p>
            <div>
              <Input
                className={'mb-4 border-none shadow-none focus:ring-1 lg:w-96'}
                placeholder={'Enter Your Name'}
                name={'name'}
                value={formData.name}
                onChange={handleChange}
                style={{
                  boxShadow: 'none',
                  borderRadius: '0',
                  border: 'none',
                  borderBottom: '1px solid #ccc',
                }}
              />
            </div>
            <br />
            <div>
              <Input
                className={'mb-4 border-none shadow-none focus:ring-1 lg:w-96'}
                placeholder={'Enter Your Mobile Number'}
                name={'phoneNumber'}
                value={formData.phoneNumber}
                onChange={handleChange}
                style={{
                  boxShadow: 'none',
                  borderRadius: '0',
                  border: 'none',
                  borderBottom: '1px solid #ccc',
                }}
              />
            </div>
            <br />
            <div>
              <Input
                className={'mb-4 border-none shadow-none focus:ring-0 lg:w-96'}
                placeholder={'Enter Your Email'}
                name={'email'}
                value={formData.email}
                onChange={handleChange}
                style={{
                  boxShadow: 'none',
                  borderRadius: '0',
                  border: 'none',
                  borderBottom: '1px solid #ccc',
                }}
              />
            </div>
            <br />
            <div>
              <TextArea
                className={'mb-4 border-none shadow-none focus:ring-0 lg:w-96'}
                placeholder={'Enter Message'}
                name={'message'}
                value={formData.message}
                onChange={handleChange}
                rows={4}
                style={{
                  boxShadow: 'none',
                  borderRadius: '0',
                  border: 'none',
                  borderBottom: '1px solid #ccc',
                }}
              />
            </div>
            <br />
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#fbbf24', // Amber 400 for initial background
                    colorPrimaryHover: '#000000', // Green 600 for hover background
                    colorText: '#000000', // Black text initially
                    colorTextHover: '#ffffff', // White text on hover
                  },
                  components: {
                    Button: {
                      colorBgContainer: '#fbbf24', // Initial background color
                      colorBgHover: '#ffffff', // Green background on hover
                      colorText: '#000000', // Initial text color
                      colorTextHover: '#ffffff', // White text on hover
                    },
                  },
                }}>
                <Button
                  type={'primary'}
                  className={'w-32 h-12 lg:w-56 lg:h-10 rounded-xl bg-gradient-to-tr from-quantum-300 to-quantum-600 text-black text-lg'}
                  onClick={handleSend}
                >
                                    Send
                </Button>
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div className={'lg:p-10 w-[40vh] lg:w-[120vh]'}>
          <Image src={contactus} preview={false} style={{width: 600, height: 400}} />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import {Button, Checkbox, Divider, Form, Input, Typography} from 'antd';
import {Link} from 'react-router-dom';

const {Title} = Typography;

const onFinish = values => {
  // Form WhatsApp message with form data
  const whatsappMessage = `Full Name: ${values.fullName}\nPhone No.: ${values.phoneNumber}\nCompany Name: ${values.companyName}\nEmail: ${values.email}`;

  // Encode message for URL
  const encodedMessage = encodeURIComponent(whatsappMessage);

  // Construct WhatsApp link with the encoded message
  const whatsappLink = `https://wa.me/919011718888?text=${encodedMessage}`;

  // Open WhatsApp link
  window.open(whatsappLink, '_blank');
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const SignUp = () => {

  return (
    <section style={{position: 'relative'}}>
      <div style={{maxWidth: '1440px', margin: 'auto', padding: '0 24px'}}>
        <div style={{paddingTop: '40px', paddingBottom: '40px'}}>

          {/* Page header */}
          <div style={{maxWidth: '768px', margin: 'auto', textAlign: 'center'}}>
            <Title>Welcome. We exist to make entrepreneurship easier.</Title>
          </div>

          {/* Form */}
          <div style={{maxWidth: '384px', margin: 'auto'}}>
            <Divider className={'rounded full p-2'} style={{backgroundColor: '#d32f2f', borderColor: '#d32f2f'}}>
              <h1 className={'text-white'}>Register with your E-mail</h1>
            </Divider>
            <Form
              layout={'vertical'}
              initialValues={{remember: true}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete={'off'}
            >
              <Form.Item
                label={'Full Name'}
                name={'fullName'}
                rules={[{required: true, message: 'Please input your full name!'}]}
              >
                <Input placeholder={'First and last name'} />
              </Form.Item>
              <Form.Item
                label={'Company Name'}
                name={'companyName'}
                rules={[{required: true, message: 'Please input your company name!'}]}
              >
                <Input placeholder={'Your company or app name'} />
              </Form.Item>
              <Form.Item
                label={'Work Email'}
                name={'email'}
                rules={[{required: true, message: 'Please input your email!'}]}
              >
                <Input type={'email'} placeholder={'you@yourcompany.com'} />
              </Form.Item>
              <Form.Item
                label={'Phone No'}
                name={'phoneNumber'}
                rules={[{required: true, message: 'Please input your phone number!'}]}
              >
                <Input placeholder={'Phone No (at least 10 characters)'} />
              </Form.Item>
              <Form.Item
                name={'agreement'}
                valuePropName={'checked'}
                rules={[{required: true, message: 'Please accept the agreement!'}]}
              >
                <Checkbox>
                    I agree to be contacted by Imperial Solution about this offer as per the Imperial Solutions <Link to={'/privacy-policy'} style={{color: '#722ed1'}}>Privacy Policy</Link>.
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'} style={{width: '100%', backgroundColor: '#722ed1', borderColor: '#722ed1'}} size={'large'}>
                    Submit
                </Button>
              </Form.Item>
            </Form>
          </div>

        </div>
      </div>
    </section>
  );
};

export default SignUp;

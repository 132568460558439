import React, {useEffect, useState} from 'react';
import {Card, Image, Pagination} from 'antd';
import {Link} from 'react-router-dom';
import blogData from './BlogData.json';
import mainhome2 from '../../../assets/images/mainhome2.jpg';
import {DoubleRightOutlined} from '@ant-design/icons';

const BlogList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = blogData.articles.slice(indexOfFirstArticle, indexOfFirstArticle + articlesPerPage);

  const handlePageChange = page => {
    setCurrentPage(page);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div style={{
        backgroundImage: `url(${mainhome2})`,
        height: '50vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      className={'bg-local lg:bg-fixed '}>
        <div>
          <div className={'text-2xl md:text-5xl md:flex md:h-96 p-8 md:items-center'}>
            <h1 className={'bg-quantum-500 text-white p-3 w-24 md:w-44'}>Blogs</h1>
          </div>
        </div>
      </div>
      <div className={'md:p-12 p-4'}>
        <h1>Home <DoubleRightOutlined /> Blog List</h1>
      </div>
      <div className={'container mx-auto flex flex-wrap justify-center'}>
        {currentArticles.map((article, index) => (
          <div key={index} className={'md:p-4 md:w-96 p-5 '}>
            <Link to={`/article/${indexOfFirstArticle + index}`}>
              <Card
                hoverable
                className={'transition rounded-none border-opacity-60 border-2 border-b-4 border-r-4 hover:scale-100 duration-300 hover:-translate-y-1 border-b-orange-400 border-r-orange-400 hover:border-b-orange-400 hover:border-r-orange-400 hover:border-b-4 hover:border-r-4 overflow-hidden'}>
                <div className={'flex justify-center pb-4'}>
                  <Image
                    className={'w-40 mb-4 '}
                    preview={false}
                    src={article.image.src}
                    alt={article.image.alt}
                    width={article.image.width}
                  />
                </div>
                <h1 className={'text-2xl leading-tight font-bold pb-4 h-24'}>{article.title}</h1>
                <div>
                  <p className={'pb-2'}>{article.date}</p>
                  <p className={'pb-4 h-16 overflow-hidden'}>{article.content.paragraph}</p>
                  <h4 className={'pt-4 font-semibold pb-2'}>Posted By: {article.author}</h4>
                </div>
              </Card>
            </Link>
          </div>
        ))}
        <div className={'w-full flex justify-center pt-8 pb-12'}>
          <Pagination
            current={currentPage}
            pageSize={articlesPerPage}
            total={blogData.articles.length}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  )
  ;
};

export default BlogList;

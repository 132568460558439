import React, {useEffect, useState} from 'react';
import {Button, Card, Image} from 'antd';
import dashboard from '../../assets/images/undraw_online_ad_re_ol62.svg';
import title3 from '../../assets/images/title3.png';
import {AnimatePresence, motion} from 'framer-motion';
import {RotatingBackground} from './RotatingBackground';
import {Link} from 'react-router-dom';
import blogData from '../Blog/BlogList/BlogData.json';
import {MainHomePartners} from './MainHomePartners';
import {HidingCardsAnimation} from './HidingCardsAnimation';
import {MainHomeServiceSolutions} from './MainHomeServiceSolutions';

const data = [
  {
    id: 1,
    content: 'Facebook Marketing',
  },
  {
    id: 2,
    content: 'Instagram Marketing',
  },
  {
    id: 3,
    content: 'Telegram Marketing',
  },
  {
    id: 4,
    content: 'YouTube Marketing',
  },
  {
    id: 4,
    content: 'YouTube Marketing',
  },
];

const Home = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 3;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = blogData.articles.slice(indexOfFirstArticle, indexOfFirstArticle + articlesPerPage);

  const [index, setIndex] = useState(0);
  useEffect(() => {
    const id = setInterval(() => {
      setIndex(state => {
        if (state >= data.length - 1) {return 0;}
        return state + 1;
      });
    }, 2000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div>
      {/*1st component*/}
      <div className={'flex'}>
        <div style={{
          backgroundImage: `url(${title3})`,
          height: '110vh',
          width: '100vw',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        >
          <div className={'md:p-10 p-5 justify-center w-full'}>

            <div className={'md:ml-6 text-white'}>
              <div>
                <AnimatePresence>
                  <motion.div className={'text-5xl font-bold md:mt-32 key={items[index].id'}
                    initial={{y: 20, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    exit={{y: -20, opacity: 0}}
                    transition={{ease: 'easeInOut'}}
                  >
                    <div>
                      <h1 className={'md:mr-4'}>Imperial Solution can provide</h1>
                      <h1 className={'text-quantum-500'}>{data[index].content} </h1>
                    </div>
                  </motion.div>
                </AnimatePresence>
              </div>
              <div className={'md:w-[44rem] text-justify text-2xl'}>
                  Transform Your Brand with Innovative Advertising Solutions.
              </div>
            </div>

            {/*Hide cards*/}
            <HidingCardsAnimation />

          </div>
        </div>
      </div>

      {/*2nd component*/}
      <div className={'md:p-12 p-4 bg-infotech-100'}>
        <RotatingBackground />
        <div className={'md:flex md:flex-row md:p-3 justify-center items-center'}>
          <div className={'md:w-[40rem] md:h-[25rem]'}>
            <Image
              style={{height: 400, width: 600}}
              src={dashboard}
              preview={false}
            />
          </div>
          <div className={'md:p-4 p-2 text-justify text-sm lg:w-[35rem]'}>
            <h1 className={'md:text-2xl'}>Shaping the Future of Digital Engagement</h1>
            <p>
              We are a dynamic team of marketing professionals, strategists, and creative thinkers dedicated
              to transforming the way businesses communicate with their audiences. With a blend of industry
              expertise and cutting-edge technology, we craft customized approaches that drive engagement,
              foster loyalty, and deliver measurable results.
            </p>
            <p>
              In a rapidly evolving digital landscape, staying ahead requires more than just keeping up with
              trends. It demands a smart approach that combines deep insights, creative storytelling, and
              data-driven strategies. We are committed to understanding the unique needs and behaviors of your
              audience to create impactful interactions that resonate and inspire.
            </p>
          </div>

        </div>
      </div>

      {/*3rd component*/}
      <MainHomeServiceSolutions />

      {/*4th component*/}
      <MainHomePartners />

      {/*5th component*/}
      <div className={'md:p-10 p-5 bg-infotech-300'}>
        <div className={'justify-center'}>
          <div className={'text-center md:mb-10'}>
            <h1 className={'md:text-5xl text-3xl'}>Our Recent Blogs</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
            </p>
          </div>

          <div className={'container mx-auto flex flex-wrap justify-center'}>
            {currentArticles.map((article, index) => (
              <div key={index} className={'md:p-4 md:w-96 m-4'}>
                <Link to={`/article/${indexOfFirstArticle + index}`}>
                  <Card
                    hoverable
                    className={'transition border-opacity-60 rounded-none border-2 hover:scale-95 md:hover:scale-110 hover:-translate-y-1 duration-300 border-b-orange-400 border-r-orange-400 border-b-4 border-r-4 hover:border-b-orange-400 hover:border-r-orange-400 hover:border-b-4 hover:border-r-4 overflow-hidden'}
                  >
                    <Image
                      className={'w-40 mb-4'}
                      preview={false}
                      src={article.image.src}
                      alt={article.image.alt}
                      width={article.image.width}
                    />
                    <h1 className={'text-2xl leading-tight font-bold pb-4 h-24'}>{article.title}</h1>
                    <div>
                      <p className={'pb-2'}>{article.date}</p>
                      <p className={'pb-4 h-16 overflow-hidden'}>{article.content.paragraph}</p>
                      <h4 className={'pt-4 font-semibold pb-2'}>Posted By: {article.author}</h4>
                    </div>
                  </Card>
                </Link>
              </div>
            ))}
          </div>

          <div className={'flex justify-center p-4'}>
            <Link to={'/Blog'}>
              <Button type={'text'} className={'text-white bg-quantum-600 border-2 border-infotech-800 w-32 h-12'}>
                  Read More
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import {motion} from 'framer-motion';
import {Image} from 'antd';
import React from 'react';
import WalmartLogo from '../../../assets/images/WalmartLogo.png';
import BundleTechnology from '../../../assets/images/BundleTech.png';
import TVS from '../../../assets/images/TVS.jpg';
import EicherMotors from '../../../assets/images/Eicher Motors Logo.jpg';
import Unacademy from '../../../assets/images/Unacademy.jpg';

const partners = [
  {
    id:1,
    src:WalmartLogo,
  },
  {
    id:2,
    src:BundleTechnology,
  },
  {
    id:3,
    src:TVS,
  },
  {
    id:4,
    src:EicherMotors,
  },
  {
    id:5,
    src:Unacademy,
  },
];

const MainHomePartners = () => {
  return (
    <div className={'md:p-10 p-5 w-full justify-center'}>
      <div>
        <div className={'text-center md:mb-20'}>
          <h1 className={'md:text-5xl text-3xl'}>Featured Customers & Partners</h1>
          <p>
            Join Our Esteemed Network of Featured Customers & Partners and Experience Unmatched Excellence.
          </p>
        </div>
        <div>
          <div className={'flex flex-wrap overflow-hidden md:w-full p-4 justify-center'}>
            {partners.map(({id, src}) => (
              <div key={id}>
                <motion.div whileTap={{scale: 0.9}} whileHover={{scale: 0.8}}
                  className={'relative w-32 md:w-56 m-2 flex justify-center text-gray-400 uppercase font-bold tracking-wide transition-all duration-300 hover:text-green-500 group'}>
                  <Image src={src} preview={false} width={200} />
                  <span
                    className={'absolute -top-1 -right-2 md:-top-3 md:right-0 h-3 w-3 md:h-10 md:w-10 border-t-2 border-r-2 border-orange-700 transition-all duration-300 opacity-0 transform translate-x-full translate-y-1/2 group-hover:translate-x-0 group-hover:translate-y-0 group-hover:opacity-100'}></span>
                  <span
                    className={'absolute -bottom-1 -left-2 md:-bottom-3 md:left-0 h-3 w-3 md:h-10 md:w-10 border-b-2 border-l-2 border-orange-700 transition-all duration-300 opacity-0 transform -translate-x-full -translate-y-1/2 group-hover:translate-x-0 group-hover:translate-y-0 group-hover:opacity-100'}></span>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHomePartners;

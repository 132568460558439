import {AnimatePresence, motion} from 'framer-motion';
import {Card} from 'antd';
import {RadarChartOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from 'react';

const HidingCardsAnimation = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Adjust this value as needed
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div
      className={'md:flex md:w-full md:flex-row p-4 justify-center md:translate-y-24 lg:translate-y-24 xl:translate-y-24'}>
      <AnimatePresence>
        {isVisible && (
          <>
            <motion.div
              className={'md:m-4 m-2 relative inline-flex items-center justify-start overflow-hidden font-medium transition-all bg-transparent rounded group'}
              initial={{opacity: 0, y: 50}}
              animate={{opacity: 1, y: 0}}
              exit={{opacity: 0, y: 50}}
              transition={{duration: 0.5}}
            >
              <div
                className={'w-[120%] h-96 rounded bg-orange-600 absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all md:translate-y-24 mb-9 ml-0 group-hover:-ml-10 group-hover:mb-24 group-hover:translate-y-24 group-hover:translate-x-4'}></div>
              <Card className={'justify-center text-white bg-transparent border-0 md:w-[25rem] w-64'}>
                <p><RadarChartOutlined /></p>
                <h1 className={'md:text-2xl md:font-bold'}>Quick and Safe Order Processing</h1>
                <p className={'md:text-xl text-sm text-justify'}>
                  We prioritize efficiency and security in handling your digital marketing orders.
                  Our streamlined process ensures swift delivery of services while maintaining the highest standards of safety and confidentiality.
                </p>
              </Card>
            </motion.div>

            <motion.div
              className={'md:m-4 m-2 relative inline-flex items-center justify-start overflow-hidden font-medium transition-all bg-transparent rounded group'}
              initial={{opacity: 0, y: 50}}
              animate={{opacity: 1, y: 0}}
              exit={{opacity: 0, y: 50}}
              transition={{duration: 0.5}}
            >
              <div
                className={'w-[120%] h-96 rounded bg-orange-600 absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all md:translate-y-24 mb-9 ml-0 group-hover:-ml-10 group-hover:mb-24 group-hover:translate-y-24 group-hover:translate-x-4'}></div>
              <Card className={'justify-center text-white bg-transparent border-0 md:w-[25rem] w-64'}>
                <p><RadarChartOutlined /></p>
                <h1 className={'md:text-2xl md:font-bold'}>Affordable Prices</h1>
                <p className={'md:text-xl text-sm text-justify'}>
                  Achieve your marketing objectives without breaking the bank. Discover budget-friendly options tailored to your needs today!
                </p>
              </Card>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HidingCardsAnimation;

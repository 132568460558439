import React from 'react';
import {motion} from 'framer-motion';

const iconVariants = {
  animate: {
    x: [10, 20, 40, 15],
    y: [10, 20, -30, 15],
    scale: [1, 1.2, 1.4, 1, 1],
    rotate: [0, 0, 270, 270, 0],
    borderRadius: ['20%', '20%', '50%', '50%', '20%'],
    transition: {
      duration: 6,
      repeat: Infinity,
    },
  },
};

const icons = [
  {background: 'blue', top: '10%', right: '10%'},
  {background: 'red', top: '30%', right: '70%'},
  {background: 'green', top: '50%', right: '30%'},
  {background: 'yellow', top: '70%', right: '50%'},
  {background: 'purple', top: '90%', right: '20%'},
  {background: 'black', top: '110%', right: '60%'},
];

function RotatingBackground() {
  return (
    <div className={'fixed top-0 right-0 w-[50vw] h-[50vh] pointer-events-none -z-10'}>
      {icons.map((icon, index) => (
        <motion.div
          key={index}
          style={{
            height: '20px',
            width: '20px',
            background: icon.background,
            position: 'relative',
            top: icon.top,
            left: icon.right,
          }}
          variants={iconVariants}
          animate={'animate'}
        />
      ))}
    </div>
  );
}

export default RotatingBackground;

import React, {useEffect} from 'react';
import {Tabs} from 'antd';
import {DoubleRightOutlined} from '@ant-design/icons';
import mainHome from '../../assets/images/mainHome.jpg';
import {SocialMediaServices} from './SocialMediaServices';
import {GoogleAdSens} from './GoogleAdSens';
import {PaymentSolution} from './PaymentSolution';

const onChange = key => {
  console.log(key);
};
const items= [
  {
    key: '1',
    label: 'Social Media Services',
    children: <SocialMediaServices />,
  },
  {
    key: '2',
    label: 'Google Ad Sens',
    children: <GoogleAdSens />,
  },
  {
    key: '3',
    label: 'Payment Solutions',
    children:<PaymentSolution />,
  },
];

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div style={{
        backgroundImage: `url(${mainHome})`,
        height: '50vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      className={'bg-local lg:bg-fixed'}>
        <div>
          <div className={'text-2xl md:text-5xl md:flex md:h-96 p-8 md:items-center'}>
            <h1 className={'bg-quantum-500 text-white p-3 w-44 md:w-80'}>Our Products</h1>
          </div>
        </div>
      </div>
      <div className={'md:p-12 p-4'}>
        <h1>Home <DoubleRightOutlined />Products</h1>
        <div>
          <Tabs defaultActiveKey={'1'} items={items} onChange={onChange} size={'large'} />
        </div>
      </div>

    </div>
  );
};

export default Products;

import React, {useEffect, useState} from 'react';
import {ClockCircleOutlined, DownOutlined, UpOutlined} from '@ant-design/icons';
import {Button, Card, Form, Image, Input, Modal, message} from 'antd';
import {GoogleAdSorting} from './GoogleAdSorting';
import GoogleAdSensData from './GoogleAdSens.json';
import QR from '../../../assets/images/QR (1).png';

const GoogleAdSens = () => {
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = GoogleAdSensData.GoogleAdData.category;
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Your form has been submitted successfully.',
    });
  };

  const handleSelect = value => {
    const selected = categories.find(category => category.title === value);
    setSelectedCategory(selected);
  };

  const showDescriptionModal = productItem => {
    setSelectedProduct(productItem);
    setIsDescriptionModalVisible(true);
  };

  const showPriceModal = productItem => {
    setSelectedProduct(productItem);
    setIsPriceModalVisible(true);
  };

  const handleDescriptionOk = () => {
    setIsDescriptionModalVisible(false);
  };

  const handleDescriptionCancel = () => {
    setIsDescriptionModalVisible(false);
  };

  const handlePriceOk = () => {
    setIsPriceModalVisible(false);
  };

  const handlePriceCancel = () => {
    setIsPriceModalVisible(false);
  };

  const handleSecondModalOk = () => {
    setIsSecondModalVisible(false);
  };

  const handleSecondModalCancel = () => {
    setIsSecondModalVisible(false);
  };

  const handleButtonClick = () => {
    setIsSecondModalVisible(true);
  };

  const onFinish = values => {
    const whatsappMessage = `Full Name: ${values.name}\nPhone No.: ${values.phone}\nCity: ${values.city}\nEmail: ${values.email}\nTransaction ID:${values.TransactionId}`;
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappLink = `https://wa.me/919011718888?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <GoogleAdSorting />
      <div className={'py-4'}>
        <div className={'pb-12 flex flex-wrap justify-center'}>
          {GoogleAdSensData.GoogleAdData.category.map((category, catIndex) => (
            <div key={catIndex}>
              <div className={'flex items-center justify-self-center bg-yellow-500 md:w-[150vh] md:h-12 m-4 px-4 md:px-6 rounded-md'}>
                <h1 className={'text-xl text-white mt-2'}>{category.title}</h1>
              </div>
              {category.content.map((productItem, prodIndex) => (
                <div key={`${catIndex}-${prodIndex}`} className={'md:w-[150vh] w-80 md:h-32 m-4 md:px-6'}>
                  <Card hoverable className={'duration-500 w-full rounded-none border-r-[3px] border-b-[3px] h-64 md:h-32 border-orange-400 hover:border-orange-400 overflow-hidden'}>
                    <div className={'flex flex-wrap justify-between'}>
                      <div>
                        <h1 className={'text-base leading-tight md:w-[100vh] h-24 md:h-12 overflow-y-scroll md:overflow-hidden'}>
                          {productItem.description}
                        </h1>
                        <div className={'flex flex-wrap'}>
                          <div className={'flex flex-col md:flex-row md:items-center md:pr-3'}>
                            <div className={'md:pr-3 pr-1'}>
                              <DownOutlined className={'text-green-700 md:pr-1'} /> Min: {productItem.min}
                            </div>
                            <div className={'md:pr-3 pr-1'}>
                              <UpOutlined className={'text-blue-700 md:pr-1'} /> Max: {productItem.max}
                            </div>
                            <div className={'md:pr-3'}>
                              <ClockCircleOutlined className={'text-orange-700 md:pr-1'} /> Average Time: {productItem.average_time}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={'flex md:flex-col mt-2 md:mt-0'}>
                        <Button className={'w-32 md:w-28 md:h-10 bg-orange-400 hover:bg-green-600 py-2 rounded-md text-white font-semibold mr-2 md:mb-1'}
                          onClick={() => showPriceModal(productItem)}>
                                                    ₹{productItem.price}
                        </Button>
                        <Button className={'w-32 md:w-28 md:h-10 bg-orange-400 hover:bg-green-600 py-2 rounded-md text-white font-semibold'}
                          onClick={() => showDescriptionModal(productItem)}>
                                                    Description
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Modal
        title={'Product Description'}
        visible={isDescriptionModalVisible}
        onOk={handleDescriptionOk}
        onCancel={handleDescriptionCancel}
      >
        {selectedProduct && (
          <div>
            <h2>{selectedProduct.description}</h2>
            <p>Min: {selectedProduct.min}</p>
            <p>Max: {selectedProduct.max}</p>
            <p>Average Time: {selectedProduct.average_time}</p>
            <p>Price: ₹{selectedProduct.price}</p>
          </div>
        )}
      </Modal>

      <Modal
        title={'Product Price'}
        visible={isPriceModalVisible}
        onOk={handlePriceOk}
        onCancel={handlePriceCancel}
      >
        {selectedProduct && (
          <div className={'flex justify-center items-center flex-col'}>
            <div>
              <Image src={QR} preview={false} style={{width: 300}} />
            </div>
            <div className={'text-xl pt-8 text-blue-500'}>
              <Button className={'h-10'} onClick={handleButtonClick}>
                                Click here after payment
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title={'Enter Your Details'}
        visible={isSecondModalVisible}
        onOk={() => {
          handleSecondModalOk();
          handlePriceOk();
          success();
        }}
        onCancel={handleSecondModalCancel}>
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          {contextHolder}
          <Form.Item
            label={'Name'}
            name={'name'}
            rules={[{required: true, message: 'Please enter your name!'}]}>
            <Input placeholder={'Enter your name'} />
          </Form.Item>
          <Form.Item
            label={'Phone Number'}
            name={'phone'}
            rules={[
              {required: true, message: 'Please enter your phone number!'},
              {pattern: /^\d+$/, message: 'Phone number must be numeric!'},
            ]}>
            <Input maxLength={10} type={'number'} placeholder={'Enter your phone number'} />
          </Form.Item>
          <Form.Item
            label={'Transaction Id'}
            name={'TransactionId'}
            rules={[
              {required: true, message: 'Please enter your phone number!'},
              {pattern: /^\d+$/, message: 'Phone number must be numeric!'},
            ]}>
            <Input type={'number'} maxLength={10} placeholder={'Enter your phone number'} />
          </Form.Item>
          <Form.Item
            label={'City'}
            name={'city'}
            rules={[{required: true, message: 'Please enter your city!'}]}>
            <Input placeholder={'Enter your city'} />
          </Form.Item>
          <Form.Item
            label={'E-mail'}
            name={'email'}
            rules={[{required: true, message: 'Please enter your email!'}]}>
            <Input placeholder={'Enter your email'} />
          </Form.Item>
          <Form.Item className={'flex justify-center pt-2'}>
            <p className={'flex text-center  text-sm'}>
                            Thank you for completing the form! Click the &lsquo;Submit&lsquo; button below, and you will be redirected to WhatsApp for further communication
            </p>
          </Form.Item>
          <Form.Item className={'flex justify-center'}>
            <Button type={'primary'} htmlType={'submit'}>
                            Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GoogleAdSens;

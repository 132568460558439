import React, {useEffect, useState} from 'react';
import {Button, Drawer, Image, Layout} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import {NavLink, useLocation} from 'react-router-dom';
import {LeftMenu} from './LeftMenu';
import logo from '../../assets/images/final logo horizontal.png';
import navbg from '../../assets/images/navbg3.png';

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const location = useLocation();
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <nav className={'sticky top-0 z-50 bg-white shadow border-gray-200'}>
      <Layout>
        <Layout.Header style={{backgroundImage: `url(${navbg})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover'}}
          className={'flex items-center justify-between bg-infotech-950 p-0'}>
          <div className={'md:w-36 w-32 md:m-10 m-6 flex justify-center'}>
            <NavLink exact to={'/AboutUs'} className={'nav-logo'}>
              <Image src={logo} preview={false} className={'mt-8'} alt={''} />
            </NavLink>
          </div>
          <div className={'md:flex-grow flex items-center justify-end mr-10'}>
            <div className={'hidden md:flex'}>
              <LeftMenu mode={'horizontal'} />
            </div>
            <Button
              className={'md:hidden text-gray-600 hover:text-gray-900 focus:outline-none'}
              type={'text'}
              onClick={toggleDrawer}>
              <MenuOutlined />
            </Button>
            {/*<div className={'hidden md:flex'}>*/}
            {/*  <RightMenu mode={'horizontal'} />*/}
            {/*</div>*/}
            <div>
              <Drawer
                title={(
                  <Image src={logo} alt={'Brand Logo'}
                    preview={false}
                    style={{width: '100px', height: 'auto'}}
                  />
                )}
                placement={'right'}
                closable={true}
                onClose={toggleDrawer}
                open={isDrawerOpen}
                style={{zIndex: 99999}}
              >
                <LeftMenu mode={'inline'} />
                {/*<RightMenu mode={'inline'} />*/}
              </Drawer>
            </div>
          </div>
        </Layout.Header>
      </Layout>
    </nav>
  );
};

export default Navbar;

import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {List} from 'antd';
import blogData from '../BlogData.json';
import {DoubleRightOutlined} from '@ant-design/icons';

const BlogDetailScreen = () => {
  const {id} = useParams();
  const article = blogData.articles[id];

  if (!article) {
    return;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={'md:p-12 p-4'}>
        <h1>Home <DoubleRightOutlined /> Blog List <DoubleRightOutlined /> Detail Blogs</h1>
      </div>
      <div className={'container mx-auto p-2 sm:p-4 md:p-6 lg:p-8 flex flex-col items-center'}>
        <div className={'w-full max-w-4xl p-4'}>
          <h1 className={'text-3xl sm:text-4xl mb-4 text-center font-bold'}>{article.title}</h1>
          <div className={'flex justify-center'}>
            <div className={'w-full max-w-3xl p-2'}>
              <img
                className={'w-full max-w-3xl pb-4 object-cover'}
                src={article.image.src}
                alt={article.image.alt}
                style={{maxHeight: '500px'}}
              />
            </div>
          </div>
          <div className={'text-left px-4 sm:px-8 md:px-16 lg:px-24'}>
            <p className={'pb-2'}><strong>Date:</strong> {article.date}</p>
            <p className={'pb-4'}><strong>Posted By:</strong> {article.author}</p>
            <div className={'text-base pb-6'}>{article.content.paragraph}</div>
            <h2 className={'text-2xl font-bold pb-4'}>Trends</h2>
            {article.content.trends.map((trend, index) => (
              <div key={index} className={'pb-6 text-base'}>
                <h3 className={'text-xl font-bold pb-2'}>{index + 1}. {trend.title}</h3>
                <p className={'pb-2'}>{trend.description}</p>
                {trend.subpoints && trend.subpoints.length > 0 && (
                  <List
                    size={'small'}
                    dataSource={trend.subpoints}
                    renderItem={(subpoint, subIndex) => (
                      <List.Item key={subIndex}>
                        <span className={'inline-block w-2 h-2 bg-black rounded-full mr-2'} />
                        {subpoint}
                      </List.Item>
                    )}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailScreen;

import React from 'react';
import {BlogList} from './BlogList';
import {BlogDetailScreen} from './BlogList/BlogDetailScreen';

const Blog = () => {
  return(
    <>
      <BlogList />
      <BlogDetailScreen />
    </>

  );
};

export default Blog;

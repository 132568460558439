import React from 'react';
import {FaTelegramPlane} from 'react-icons/fa';
import footer from '../../assets/images/WorldMapBackground.png';
import logo from '../../assets/images/final only logo.jpg';
import {Card, Image} from 'antd';
import {InstagramOutlined, WhatsAppOutlined, YoutubeOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <div>
        <div className={'md:p-10 p-5 md:flex md:flex-row gap-4 justify-center bg-fixed'} style={{
          backgroundImage: `url(${footer})`,
        }}>
          <div>
            <Card
              className={'text-white rounded-none border-none bg-transparent'}
              style={{
                width: 300,
              }}
            >
              <div className={'flex mb-4'}>
                <div>
                  <Image src={logo} preview={false} style={{width: 50}} />
                </div>
                <div className={'flex flex-col'}>
                  <h1 className={'text-xl ml-2'}>IMPERIAL SOLUTION</h1>
                </div>
              </div>

              <div className={'flex gap-2'}>
                <a href={'https://www.instagram.com/imperialsolution.in?igsh=NmJ3N3BpcGl6ZXhk'} target={'_blank'}
                  rel={'noreferrer'}><InstagramOutlined href={''}
                    className={'rounded-full w-10 p-2 h-10 text-3xl bg-zinc-700 transition duration-300 hover:bg-quantum-500'} /></a>
                <a href={'https://wa.me/message/S5XY3J5KKSHEF1'} target={'_blank'}
                  rel={'noreferrer'}><WhatsAppOutlined
                    className={'rounded-full w-10 p-2 h-10 text-3xl bg-zinc-700 transition duration-300 hover:bg-quantum-500'} /></a>
                <a href={'https://www.youtube.com/@imperialsolution'} target={'_blank'}
                  rel={'noreferrer'}><YoutubeOutlined
                    className={'rounded-full w-10 p-2 h-10 text-3xl bg-zinc-700 transition duration-300 hover:bg-quantum-500'} /></a>
                <a href={'https://t.me/imperialsolution'} target={'_blank'} rel={'noreferrer'}>
                  <FaTelegramPlane
                    className={'rounded-full w-10 p-2 h-10 text-3xl bg-zinc-700 transition duration-300 hover:bg-quantum-500'}
                  />
                </a>
              </div>
            </Card>
          </div>

          <div>
            <Card className={'text-white rounded-none border-none bg-transparent'}
              style={{
                width: 300,
              }}
            >
              <h1 className={'text-xl border-b-2 w-16 border-quantum-600'}>Services</h1>
              <p>Instagram Marketing</p>
              <p>Facebook Marketing</p>
              <p>YouTube Marketing</p>
              <p>Telegram Marketing</p>
            </Card>
          </div>

          <div>
            <Card
              className={'text-white rounded-none border-none bg-transparent'}
              style={{
                width: 300,
              }}
            >
              <h1 className={'text-xl border-b-2 w-[5.9rem] border-quantum-600'}>Quick&nbsp;Links</h1>
              <Link to={'/'} className={'hover:text-infotech-100'}><p>Home</p></Link>
              <Link to={'/AboutUs'} className={'hover:text-infotech-100'}><p>About Us</p></Link>
              <Link to={'/Blog'} className={'hover:text-infotech-100'}><p>Blog</p></Link>
              <Link to={'/Products'} className={'hover:text-infotech-100'}><p>Products</p></Link>
              <Link to={'/ContactUs'} className={'hover:text-infotech-100'}><p>Contact</p></Link>
            </Card>
          </div>

          <div>
            <Card
              className={'text-white rounded-none border-none bg-transparent'}
              style={{
                width: 300,
              }}
            >
              <h1 className={'text-xl border-b-2 w-16 border-quantum-600'}>Contacts</h1>
              <p>Address: 504, Crystal Square, Near Khanvilkar Petrol Pump, Nagla Park, Kolhapur,
                   Maharashtra 416003 IN</p>
              <p>Email: info@imperialsolution.in</p>
              <p>Phone: 9011718888</p>
            </Card>
          </div>
        </div>
      </div>
      <div className={'flex p-4 justify-between bg-black text-white'}>
        <text>Imperial Solution | All Rights Reserved.</text>
        <Link to={'https://quantuminfotechs.com/'} target={'_blank'}><text>Powered by: Quantum Infotech</text></Link>
      </div>
    </div>
  );
};

export default Footer;

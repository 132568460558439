import {motion} from 'framer-motion';
import {Card} from 'antd';
import {
  CustomerServiceOutlined,
  GlobalOutlined, InstagramOutlined,
  RiseOutlined,
  StarOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import React from 'react';
import cardData from '../Home.json';

const serviceInfo = cardData.data;

const MainHomeServiceSolutions = () => {
  return (
    <div className={'md:p-10 p-5 animated-background'}>
      <div>
        <div className={'text-center md:mb-20 mb-10'}>
          <h1 className={'md:text-5xl text-3xl'}>We Offer Professional Solutions</h1>
          <p>
              Unlock Your Business Potential with Our Professional Solutions
          </p>
        </div>
        <div className={'flex flex-wrap justify-center'}>
          {serviceInfo.map((info, index) => (
            <div key={index} className={'m-4'}>
              <motion.div
                className={'box'}
                whileHover={{scale: 1.1}}
                transition={{type: 'spring', stiffness: 400, damping: 10}}
              >
                <Card className={'rounded-none md:w-96 md:h-64 p-4'}>
                  {index === 0 && <div className={'text-4xl pb-2'}><UsergroupAddOutlined /></div>}
                  {index === 1 && <div className={'text-4xl pb-2'}><CustomerServiceOutlined /></div>}
                  {index === 2 && <div className={'text-4xl pb-2'}><RiseOutlined /></div>}
                  {index === 3 && <div className={'text-4xl pb-2'}><StarOutlined /></div>}
                  {index === 4 && <div className={'text-4xl pb-2'}><GlobalOutlined /></div>}
                  {index === 5 && <div className={'text-4xl pb-2'}><InstagramOutlined /></div>}
                  <p className={'font-bold text-xl'}>{info.title}</p>
                  <p>
                    {info.description}
                  </p>
                </Card>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainHomeServiceSolutions;

import React, {useEffect} from 'react';
import mainhome3 from '../../assets/images/mainhome3.jpg';
import {Card, Image} from 'antd';
import {RotatingBackground} from '../Home/RotatingBackground';
import dashboard from '../../assets/images/undraw_online_ad_re_ol62.svg';
import dashboard1 from '../../assets/images/aboutbg.svg';
import {AboutSlider} from './AboutSlider';
import {DoubleRightOutlined} from '@ant-design/icons';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <div>
      <div style={{
        backgroundImage: `url(${mainhome3})`,
        height: '50vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      className={'bg-local lg:bg-fixed'}>
        <div>
          <div className={'text-2xl md:text-5xl md:flex md:h-96 p-8 md:items-center'}>
            <h1 className={'bg-quantum-500 text-white p-3 w-32 md:w-60'}>About Us</h1>
          </div>
        </div>
      </div>
      <div className={'md:p-12 p-4 bg-infotech-100'}>
        <h1>Home <DoubleRightOutlined /> About Us</h1>
        <RotatingBackground />
        <div className={'md:flex md:flex-row md:p-3 justify-center items-center'}>
          <div className={'md:w-[40rem] md:h-[25rem]'}>
            <Image
              style={{height: 400, width: 600}}
              src={dashboard}
              preview={false}
            />
          </div>
          <div className={'md:p-4 p-2 text-sm lg:w-[35rem]'}>
            <h1 className={'md:text-2xl'}>Shaping the Future of Digital Engagement</h1>
            <p>
                We are a dynamic team of marketing professionals, strategists, and creative thinkers dedicated
                to transforming the way businesses communicate with their audiences. With a blend of industry
                expertise and cutting-edge technology, we craft customized approaches that drive engagement,
                foster loyalty, and deliver measurable results.
            </p>
            <p>
                In a rapidly evolving digital landscape, staying ahead requires more than just keeping up with
                trends. It demands a smart approach that combines deep insights, creative storytelling, and
                data-driven strategies. We are committed to understanding the unique needs and behaviors of your
                audience to create impactful interactions that resonate and inspire.
            </p>
          </div>
        </div>

        <div className={'md:flex md:flex-row md:p-2 justify-center'}>
          <div className={'md:p-2 p-2 text-justify text-sm'}>
            <h1 className={'md:text-2xl'}>What We Do</h1>
            <p>Our comprehensive suite of services includes:</p>
            <p>
              <span className={'inline-block w-2 h-2 bg-black rounded-full lg:mr-2'} />
              Audience Analysis: Leveraging advanced analytics to understand your audience is preferences,
              behaviors, and needs.
            </p>
            <p>
              <span className={'inline-block w-2 h-2 bg-black rounded-full lg:mr-2'} />
              Brand Strategy: Developing and refining your brand identity to ensure it stands out in a crowded
              marketplace.
            </p>
            <p>
              <span className={'inline-block w-2 h-2 bg-black rounded-full lg:mr-2'} />
              Digital Marketing: Utilizing SEO, social media, email marketing, and more to reach your audience
              where they are most active.
            </p>
          </div>
          <div className={'md:w-[50rem] md:h-96'}>
            <Image
              style={{height: 400, width: 500}}
              src={dashboard1}
              preview={false}
            />
          </div>
        </div>
      </div>

      <AboutSlider />

      <div className={'md:flex md:p-12 p-8 animated-background justify-center gap-4 overflow-hidden'}>
        <div
          className={'group mb-6 relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10'}>
          <span
            className={'absolute top-10 z-0 h-20 w-20 rounded-full bg-infotech-400 transition-all duration-300 group-hover:scale-[10]'}></span>
          <div className={'relative z-10 mx-auto w-72 h-[42vh] md:max-w-md'}>
            <Card
              className={'backdrop-filter lg:w-72 lg:h-80 h-80 w-72 border-white backdrop-blur-lg bg-white border transition duration-300 hover:bg-white shadow-md shadow-orange-700'}>
              <h1 className={'text-xl'}>Who We Are</h1>
              <p>
              Imperial Solution is a premier advertising agency dedicated to
              transforming brands and driving business growth through innovative and strategic advertising
              solutions. Founded on the principles of creativity, innovation, and strategic insight, we
              have become a trusted partner for businesses looking to make a lasting impact in their
              markets.
              </p>
            </Card>
          </div>
        </div>
        <div
          className={'group mb-6 relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10'}>
          <span
            className={'absolute top-10 z-0 h-20 w-20 rounded-full bg-infotech-400 transition-all duration-300 group-hover:scale-[10]'}></span>
          <div className={'relative z-10 mx-auto w-72 h-[42vh] md:max-w-md'}>
            <Card
              className={'backdrop-filter backdrop-blur-lg h-80 w-72 lg:w-72 lg:h-80 bg-white border-white transition duration-300 hover:bg-white shadow-md shadow-orange-700'}>
              <h1 className={'text-xl'}>Our Vision</h1>
              <p>
              To be the leading force in innovative advertising, consistently
              setting new standards of excellence and creativity, and empowering brands to form deep,
              impactful connections with their audiences worldwide.
              </p>
            </Card>
          </div>
        </div>
        <div
          className={'group mb-6 relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10'}>
          <span
            className={'absolute top-10 z-0 h-20 w-20 rounded-full bg-infotech-400 transition-all duration-300 group-hover:scale-[10]'}></span>
          <div className={'relative z-10 mx-auto w-72 h-[42vh] md:max-w-md'}>
            <Card
              className={'backdrop-filter backdrop-blur-lg h-80 w-72 lg:w-72 lg:h-80 bg-white border-white transition duration-300 hover:bg-white/40 shadow-md shadow-orange-700'}>
              <h1 className={'text-xl'}>Our Mission</h1>
              <p>
              We aim to deliver excellence by blending creativity with strategic
              insight, ensuring each campaign is both visually stunning and effective. We build strong,
              collaborative partnerships with our clients, working closely to understand their unique
              needs and achieve their goals.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
